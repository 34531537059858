import axios from 'axios';
import {User} from '@interface/master/UserManagement';
import {ApiResponse, CommonCodeMap} from '@interface/common';
import {MenuInfo} from '@interface/auth/MenuManagement';
import {Role} from '@interface/auth/RoleManagement';


export const getViewCmCode = async (props:any) => {
    const viewCmCode: CommonCodeMap = {};
    for(const cmGroupCode of props.cmGroupCodeList) {
        const {data} = await axios.get<CommonCodeMap>(`/api/common-code/${cmGroupCode}`);
        viewCmCode[cmGroupCode] = data.item;

    }
    return  viewCmCode;
};


export const getUserLoginInfo = async () => {
    const {data} = await axios.get<ApiResponse<User>>('/api/common/login-info');
    return data;
};


export const getUserListByUserName = async (searchText: string) => {
    const {data} = await axios.get<ApiResponse<User[]>>('/api/common/user-list',  {params:{userName:searchText},headers:{showLoading:false}});
    return data;
};

export const getRoleListByRoleName = async (searchText: string) => {
    const {data} = await axios.get<ApiResponse<Role[]>>('/api/common/role-list',  {params:{roleName:searchText, useFlag: true},headers:{showLoading:false}});
    return data;
};


export const getUserMenuInfo = async () => {
    const {data} = await axios.get<ApiResponse<MenuInfo[]>>('/api/common/menu-info');
    return data;
};



export const callLogout = async () => {
   const {data} = await axios.post<ApiResponse<boolean>>('/api/logout');
   return data;
};
