import React, {useState} from 'react';
import {callLogout} from '@api/CommonApi';
import {HttpStatusCode} from 'axios';

import IconAlarm from '@icon/IconAlarm';
import IconLogout from '@icon/IconLogout';
import IconAdmin from '@icon/IconAdmin';
import {MenuInfo} from '@interface/auth/MenuManagement';
import {useRecoilValue} from 'recoil';
import {sessionInfoAtom} from '@atom/sessionInfoAtom';


function ParentMenu({parentMenu}:{parentMenu:MenuInfo}) {
    return (
        <p key={parentMenu.menuId} className='menu-main-link'><a href="#">{parentMenu.menuNm}</a></p>
    );
}

function ChildMenu({parentMenu, menuList}:{parentMenu:MenuInfo, menuList:MenuInfo[]}) {
    return (<div className='menu-sub-link'>
            {menuList.filter((menu) => menu.upMenuId === parentMenu.menuId && menu.useFlag).map((menu) => {
               return <p key={menu.menuId} style={{cursor:'pointer'}}><a onClick={()=>window.location.replace(menu.menuUri)}>{menu.menuNm}</a></p>;
            })}
        </div>
    );
}


function Menu({menuInfo}: { menuInfo: MenuInfo[] }) {
    return (
        <>
            {menuInfo.filter((item) => item.menuTypeCd === 'D').map((parentMenu) => {
                return <>
                    <ParentMenu parentMenu={parentMenu}/>

                </>;
            })}

            <div className='menu-sub'>
            {menuInfo.filter((item) => item.menuTypeCd === 'D').map((parentMenu) => {
                return <>
                    <div className='menu-sub-link'>
                        <ChildMenu parentMenu={parentMenu} menuList={menuInfo}/>
                    </div>
                </>;
            })}
            </div>
        </>
    );
}

const Header = ({menuInfo}: { menuInfo: MenuInfo[] }) => {
    const sessionInfo = useRecoilValue(sessionInfoAtom);
    const [isMenuHovered, setIsMenuHovered] = useState(false);

    const handleMouseEnter = () => { setIsMenuHovered(true); };
    const handleMouseLeave = () => { setIsMenuHovered(false); };

    const logout = async () => {
       const data = await callLogout();
       if(data.code === HttpStatusCode.Ok)
           location.href = location.pathname;
    };

    return (
        <div className='header_wrap' >
            <div
                className={!isMenuHovered ? 'menu-dim' : 'menu-dim on'}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            ></div>

            <div className='inner'>
                <a className='logo' href="/">Lxnn system</a>

                <div
                    className="menu"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <Menu menuInfo={menuInfo} />
                </div>

                <div className='login'>
                    <div className='user'>
                        <div className='thumb'>
                            <IconAdmin/>
                        </div>
                        <div className='name'>{(sessionInfo && sessionInfo.userName) ?? ''}님</div>
                    </div>

                    <div className='alarm'>
                        <IconAlarm />
                        <span className='num'>10</span>
                    </div>

                    <div className='logout' onClick={logout}>
                        <IconLogout />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;