import React, {useEffect} from 'react';
import {ApiResponse} from '@interface/common';
import CustomButton from '@component/CustomButton';
import axios, {HttpStatusCode} from 'axios';
import {getUserLoginInfo} from '@api/CommonApi';
import {useForm} from 'react-hook-form';
import CustomValidFormInput from '@component/form/CustomValidFormInput';
import {useCookies} from 'react-cookie';
import CustomValidFormCheckbox from '@component/form/CustomValidFormCheckbox';

const Login = () => {
    const {register: saveFormRegister
        , control: saveFormControl
        , handleSubmit: saveFormHandleSubmit
        , setValue: saveFormSetValue
        , getValues:saveFormGetValues} = useForm<{ userId: string, passwd: string, isRememberId:boolean }>({mode:'onChange'});
    const [cookies, setCookie, removeCookie] = useCookies(['id'], {doNotParse: true});


    const handleLogin = async() => {
        const param =   {userId:saveFormGetValues('userId'), passwd:saveFormGetValues('passwd')};
        const {data} = await axios.post<ApiResponse<boolean>>('/api/login',param);
        if(data.code === HttpStatusCode.Ok) {
            const ret = await getUserLoginInfo();
            if(ret.code === HttpStatusCode.Ok) {
                saveFormGetValues('isRememberId')?setCookie('id', saveFormGetValues('userId')): removeCookie('id');
                location.href = location.pathname;
            }

        }

        return data;
    };

    useEffect(() => {
        if(cookies.id) {
            saveFormSetValue('userId',  cookies.id);
            saveFormSetValue('isRememberId', true);
        }
    }, []);
    return (
        <>
            <div className='login-dim'></div>
            <div className='login-wrap'>
                <h2 className='logo'>Lxnn system</h2>

                <div className='txt'>국가지점번호 IoT 센서로 시민 재난안전망 시스템</div>

                <form className={'ant-form'} onSubmit={saveFormHandleSubmit(handleLogin)}>
                    <div className={'ant-form-item ant-form-item-control-input'}>
                        <CustomValidFormInput
                            placeholder="아이디"
                            control={saveFormControl}
                            onChangeValue={(v) => {
                            }}
                            {...saveFormRegister('userId', {required: '아이디를 입력해주세요.'})}
                        />
                        <CustomValidFormInput
                            type={'password'}
                            placeholder="비밀번호"
                            required={true}
                            control={saveFormControl}
                            onChangeValue={(v) => {
                            }}
                            {...saveFormRegister('passwd', {required: '비밀번호를 입력해주세요.'})}
                        />
                    </div>

                    <CustomButton className='btn' type="primary"
                                  onClick={saveFormHandleSubmit(handleLogin)}>로그인</CustomButton>

                    <div className='box'>
                        <CustomValidFormCheckbox
                            name={'isRememberId'}
                            control={saveFormControl}
                            onChange={(p) => {
                                saveFormSetValue('isRememberId', p.target.checked);
                            }}>아이디 저장</CustomValidFormCheckbox>

                        <div className='find'>
                            <a className='link' href="">아이디찾기</a>
                            <a className='link' href="">비밀번호찾기</a>
                        </div>
                    </div>


                    <div className='copy-right'>
                        Copyright ©Lxnn. All Rights Reserved.
                    </div>
                </form>
            </div>
        </>
    );

};

export default Login;
