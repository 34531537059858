import React, {Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import ErrorPage404 from '@error/ErrorPage404';
import {retryLazy} from '@util/LazyUtil';
import {MenuInfo} from '@interface/auth/MenuManagement';
import Dashboard from '@page/Dashboard';
import {Breadcrumb} from 'antd';
import IconStepArrow from '@icon/IconStepArrow';
import IconHome from '@icon/IconHome';


function Container({menuInfo, onChange}:{menuInfo:MenuInfo[], onChange:(flag:boolean)=> void}) {
    const getRoute = (routeList:React.ReactNode[]) => {
        const Template =  retryLazy(() => import('@page/Template'));

        // 퍼블용(완료 후 삭제 필요)
        const GuideComp         = retryLazy(() => import('@page/Guide'));
        const ScreenType01Comp  = retryLazy(() => import('@page/ScreenType01'));
        const ScreenType02Comp  = retryLazy(() => import('@page/ScreenType02'));
        const ScreenType03Comp  = retryLazy(() => import('@page/ScreenType03'));
        const ScreenType04Comp  = retryLazy(() => import('@page/ScreenType04'));
        const ScreenType05Comp  = retryLazy(() => import('@page/ScreenType05'));
        const ScreenType06Comp  = retryLazy(() => import('@page/ScreenType06'));
        const ScreenType07Comp  = retryLazy(() => import('@page/ScreenType07'));
        const ScreenType08Comp  = retryLazy(() => import('@page/ScreenType08'));
        const Dashboard         = retryLazy(() => import('@page/Dashboard'));


        // 가이드
        routeList.push( <Route key={'Guide'} path={'/Guide'} element={<GuideComp onChange={onChange} />} /> );
        // 화면 유형 01 - 사용자관리
        routeList.push( <Route key={'ScreenType01'} path={'/ScreenType01'} element={<ScreenType01Comp onChange={onChange} />} /> );
        // 화면 유형 02 - 메뉴관리
        routeList.push( <Route key={'ScreenType02'} path={'/ScreenType02'} element={<ScreenType02Comp onChange={onChange} />} /> );
        // 화면 유형 03 - 권한관리
        routeList.push( <Route key={'ScreenType03'} path={'/ScreenType03'} element={<ScreenType03Comp onChange={onChange} />} /> );
        // 화면 유형 04 - 권한별 메뉴관리
        routeList.push( <Route key={'ScreenType04'} path={'/ScreenType04'} element={<ScreenType04Comp onChange={onChange} />} /> );
        // 화면 유형 05 - 사용자별 메뉴권한
        routeList.push( <Route key={'ScreenType05'} path={'/ScreenType05'} element={<ScreenType05Comp onChange={onChange} />} /> );
        // 화면 유형 06 - 접속정보(DB로그)
        routeList.push( <Route key={'ScreenType06'} path={'/ScreenType06'} element={<ScreenType06Comp onChange={onChange} />} /> );
        // 화면 유형 07 - 분류코드
        routeList.push( <Route key={'ScreenType07'} path={'/ScreenType07'} element={<ScreenType07Comp onChange={onChange} />} /> );
        // 화면 유형 08 - 산정보 관리
        routeList.push( <Route key={'ScreenType08'} path={'/ScreenType08'} element={<ScreenType08Comp onChange={onChange} />} /> );
        routeList.push( <Route key={'Guide'} path={'/Guide'} element={<GuideComp onChange={onChange} />} /> );
        routeList.push(<Route key={'template'} path={'/template'} element={ <Template onChange={onChange}/>} /> );

        menuInfo.filter((menu)=>menu.menuTypeCd==='V' && menu.useFlag).forEach((menu) => {
            const Comp =  retryLazy(() => import('/src/page'+menu.menuViewPath));
            routeList.push(<Route key={menu.menuId} path={menu.menuUri}
                                  element={<>
                                    <section className="title-wrap">
                                        <div className="box-flex">
                                            <h2 className="title">{menu.menuNm}</h2>

                                            <Breadcrumb
                                                separator={[
                                                    (<><IconStepArrow/></>)
                                                ]}
                                                className="bread-crumb"
                                                items={[
                                                    {
                                                        href: '',
                                                        title: (
                                                            <>
                                                                <IconHome/>
                                                                <span className="txt">{menu.menuNamePath.split('>')[1].trim()}</span>
                                                            </>
                                                        ),
                                                    },
                                                    {
                                                        title: (
                                                            <>
                                                                <span className="txt">{menu.menuNm}</span>
                                                            </>
                                                        ),
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </section>
                                    <Comp onChange={onChange}/>
                                  </>
                            } /> );
        });

        return routeList;
    };

    const createDashboard = () => {
        return <div>Dashboard 입니다!!!!</div>;
    };

    return (
        <div className={'container_wrap'}>
            <div className={'container_inner'}>
                <Suspense fallback={<></>}>
                    <Routes>
                        <Route key={'dashBoard'} path={'/'} element={<Dashboard onChange={onChange}/>}/>
                        {getRoute([])}
                        <Route key={'404-ErrorPage'} path={'*'}
                               element={menuInfo.length ? <ErrorPage404 onChange={onChange}/> : null}/>
                    </Routes>
                </Suspense>
            </div>
        </div>
    );
}

export default Container;